// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MT9a6wL9i", "e38UV0A5_"];

const serializationHash = "framer-MvcPH"

const variantClassNames = {e38UV0A5_: "framer-v-yrqm3u", MT9a6wL9i: "framer-v-8ws05o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Off: "e38UV0A5_", On: "MT9a6wL9i"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, lwP2zQb9q: click ?? props.lwP2zQb9q, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MT9a6wL9i"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lwP2zQb9q, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MT9a6wL9i", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1t30ggn = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (lwP2zQb9q) {const res = await lwP2zQb9q(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-8ws05o", className, classNames)} data-border data-framer-name={"On"} data-highlight layoutDependency={layoutDependency} layoutId={"MT9a6wL9i"} onTap={onTap1t30ggn} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-d559605d-d2a3-4776-a2c1-0436ceda3b18, rgba(255, 255, 255, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-6ee39359-b4dc-42f1-93d9-495015475b07, rgb(140, 69, 255))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{e38UV0A5_: {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({e38UV0A5_: {"data-framer-name": "Off"}}, baseVariant, gestureVariant)}><motion.div className={"framer-oqx0wc"} layoutDependency={layoutDependency} layoutId={"zsy64VwEn"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MvcPH.framer-gnmknt, .framer-MvcPH .framer-gnmknt { display: block; }", ".framer-MvcPH.framer-8ws05o { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 33px; will-change: var(--framer-will-change-override, transform); }", ".framer-MvcPH .framer-oqx0wc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 14px); overflow: hidden; position: absolute; right: 3px; top: 50%; width: 14px; will-change: var(--framer-will-change-override, transform); }", ".framer-MvcPH.framer-v-yrqm3u .framer-oqx0wc { left: 3px; right: unset; }", ".framer-MvcPH[data-border=\"true\"]::after, .framer-MvcPH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 33
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"e38UV0A5_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lwP2zQb9q":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerN0k2goOCF: React.ComponentType<Props> = withCSS(Component, css, "framer-MvcPH") as typeof Component;
export default FramerN0k2goOCF;

FramerN0k2goOCF.displayName = "AI Kit/Toggle";

FramerN0k2goOCF.defaultProps = {height: 20, width: 33};

addPropertyControls(FramerN0k2goOCF, {variant: {options: ["MT9a6wL9i", "e38UV0A5_"], optionTitles: ["On", "Off"], title: "Variant", type: ControlType.Enum}, lwP2zQb9q: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerN0k2goOCF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})