/* eslint-disable no-console, no-empty */

export var warnCache = {
  current: {}
};

/**
 * Logs a warning if the condition is not met.
 * This is used to log issues in development environment only.
 */
export function warn(condition, message) {
  if (!(process.env.NODE_ENV === 'development')) {
    return;
  }
  if (condition) {
    return;
  }
  var sanitizedMessage = message.trim();
  var hasAlreadyPrinted = warnCache.current[sanitizedMessage];
  if (!hasAlreadyPrinted) {
    warnCache.current[sanitizedMessage] = true;
    var warning = "[InstantSearch] ".concat(sanitizedMessage);
    console.warn(warning);
    try {
      // Welcome to debugging InstantSearch.
      //
      // This error was thrown as a convenience so that you can find the source
      // of the warning that appears in the console by enabling "Pause on exceptions"
      // in your debugger.
      throw new Error(warning);
    } catch (error) {}
  }
}