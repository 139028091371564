import { Motion } from "./Options/Classes/Motion";
import { MotionInstance } from "./MotionInstance";
class MotionPlugin {
    constructor(engine) {
        this.id = "motion";
        this._engine = engine;
    }
    getPlugin(container) {
        return new MotionInstance(container, this._engine);
    }
    loadOptions(options, source) {
        if (!this.needsPlugin()) {
            return;
        }
        let motionOptions = options.motion;
        if (!motionOptions?.load) {
            options.motion = motionOptions = new Motion();
        }
        motionOptions.load(source?.motion);
    }
    needsPlugin() {
        return true;
    }
}
export async function loadMotionPlugin(engine, refresh = true) {
    await engine.addPlugin(new MotionPlugin(engine), refresh);
}
