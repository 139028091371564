// Generated by Framer (e010222)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-OKwyD"

const variantClassNames = {seH5iXgac: "framer-v-xnnn18"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, image, radius, width, ...props}) => { return {...props, g3BmNHdcE: image ?? props.g3BmNHdcE ?? {src: "https://framerusercontent.com/images/07KtB00iioo573QNsZnQtSvK0Ro.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/07KtB00iioo573QNsZnQtSvK0Ro.png?scale-down-to=1024 991w,https://framerusercontent.com/images/07KtB00iioo573QNsZnQtSvK0Ro.png 1112w"}, YAstwxyxv: radius ?? props.YAstwxyxv ?? "20px"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string; alt?: string};radius?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, g3BmNHdcE, YAstwxyxv, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "seH5iXgac", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xnnn18", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"seH5iXgac"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.15)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: radiusForCorner(YAstwxyxv, 3), borderBottomRightRadius: radiusForCorner(YAstwxyxv, 2), borderTopLeftRadius: radiusForCorner(YAstwxyxv, 0), borderTopRightRadius: radiusForCorner(YAstwxyxv, 1), ...style}}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0)), sizes: componentViewport?.width || "100vw", ...toResponsiveImage(g3BmNHdcE)}} className={"framer-sg7m0i"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"pDZVFLdOq"} style={{filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}/><motion.div className={"framer-1cvcrce"} data-framer-name={"Overlay"} layoutDependency={layoutDependency} layoutId={"ISjQczm_h"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OKwyD.framer-41i0i2, .framer-OKwyD .framer-41i0i2 { display: block; }", ".framer-OKwyD.framer-xnnn18 { height: 160px; overflow: hidden; position: relative; width: 160px; will-change: var(--framer-will-change-override, transform); }", ".framer-OKwyD .framer-sg7m0i, .framer-OKwyD .framer-1cvcrce { flex: none; height: 100%; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 100%; }", ".framer-OKwyD[data-border=\"true\"]::after, .framer-OKwyD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"g3BmNHdcE":"image","YAstwxyxv":"radius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerl859ckd8N: React.ComponentType<Props> = withCSS(Component, css, "framer-OKwyD") as typeof Component;
export default Framerl859ckd8N;

Framerl859ckd8N.displayName = "AI Kit/Avatar";

Framerl859ckd8N.defaultProps = {height: 160, width: 160};

addPropertyControls(Framerl859ckd8N, {g3BmNHdcE: {__defaultAssetReference: "data:framer/asset-reference,07KtB00iioo573QNsZnQtSvK0Ro.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, YAstwxyxv: {defaultValue: "20px", title: "Radius", type: ControlType.BorderRadius}} as any)

addFonts(Framerl859ckd8N, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})