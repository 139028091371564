// Generated by Framer (1dd4fd8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/BIfVGDIAV";

const cycleOrder = ["sq4ITzUIG"];

const serializationHash = "framer-xq5Hn"

const variantClassNames = {sq4ITzUIG: "framer-v-5kyt09"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, mcJl4OEeR: title ?? props.mcJl4OEeR ?? "Keyword optimization"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mcJl4OEeR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sq4ITzUIG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-5kyt09", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sq4ITzUIG"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-kfj83i"} data-framer-name={"Checkmark"} layoutDependency={layoutDependency} layoutId={"i25NwTBg7"}><SVG className={"framer-3fwcap"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"au9uuf8Oo"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 15 15\"><path d=\"M 0 0 L 15 0 L 15 15 L 0 15 Z\" fill=\"transparent\"></path><path d=\"M 2.344 8.438 L 5.625 11.719 L 13.125 4.219\" fill=\"transparent\" stroke-width=\"0.94\" stroke=\"rgb(255, 255, 255)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={9375398094} withExternalLayout/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1dwdwj8"} data-styles-preset={"BIfVGDIAV"}>Keyword optimization</motion.p></React.Fragment>} className={"framer-tx6txo"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"gEePWZHem"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={mcJl4OEeR} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xq5Hn.framer-1m3eykg, .framer-xq5Hn .framer-1m3eykg { display: block; }", ".framer-xq5Hn.framer-5kyt09 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: auto; justify-content: flex-start; overflow: hidden; padding: 10px 0px 10px 0px; position: relative; width: 261px; }", ".framer-xq5Hn .framer-kfj83i { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-xq5Hn .framer-3fwcap { flex: none; height: 15px; position: relative; width: 15px; }", ".framer-xq5Hn .framer-tx6txo { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xq5Hn.framer-5kyt09, .framer-xq5Hn .framer-kfj83i { gap: 0px; } .framer-xq5Hn.framer-5kyt09 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-xq5Hn.framer-5kyt09 > :first-child, .framer-xq5Hn .framer-kfj83i > :first-child { margin-left: 0px; } .framer-xq5Hn.framer-5kyt09 > :last-child, .framer-xq5Hn .framer-kfj83i > :last-child { margin-right: 0px; } .framer-xq5Hn .framer-kfj83i > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37
 * @framerIntrinsicWidth 261
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"mcJl4OEeR":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framers5BWaQPvG: React.ComponentType<Props> = withCSS(Component, css, "framer-xq5Hn") as typeof Component;
export default Framers5BWaQPvG;

Framers5BWaQPvG.displayName = "AI Kit/Pricing Row";

Framers5BWaQPvG.defaultProps = {height: 37, width: 261};

addPropertyControls(Framers5BWaQPvG, {mcJl4OEeR: {defaultValue: "Keyword optimization", displayTextArea: true, title: "Title", type: ControlType.String}} as any)

addFonts(Framers5BWaQPvG, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})