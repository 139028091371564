// Generated by Framer (e010222)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Logo from "https://framerusercontent.com/modules/IQXqjAGXltPKLdfjcdtf/AdYVRfUvx2HLE5qL3Xfw/Logo.js";
const LogoFonts = getFonts(Logo);

const serializationHash = "framer-DHOxX"

const variantClassNames = {jYygLEYDc: "framer-v-szax3m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, warren, width, ...props}) => { return {...props, h5mTi1koL: warren ?? props.h5mTi1koL ?? "Section Title"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;warren?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, h5mTi1koL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "jYygLEYDc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-szax3m", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"jYygLEYDc"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-n7kv6j-container"} layoutDependency={layoutDependency} layoutId={"b8hvA0BPu-container"}><Logo company={"Framer"} height={"100%"} id={"b8hvA0BPu"} isSearch={false} layoutId={"b8hvA0BPu"} radius={100} srcFile={"https://framerusercontent.com/assets/s6PTI0917GyaZC8qM5Tzk1HIAA.png"} srcType={"Upload"} srcUrl={""} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItcmVndWxhcg==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "140%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-0d1c15e4-19d4-4f59-abec-7c72f6c1a824, rgb(221, 255, 0)))"}}>Section Title</motion.p></React.Fragment>} className={"framer-45y7y5"} data-framer-name={"INTRODUCING [NAME]"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"KSzC0xnxO"} style={{"--extracted-r6o4lv": "var(--token-0d1c15e4-19d4-4f59-abec-7c72f6c1a824, rgb(221, 255, 0))"}} text={h5mTi1koL} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DHOxX.framer-1c46plp, .framer-DHOxX .framer-1c46plp { display: block; }", ".framer-DHOxX.framer-szax3m { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-DHOxX .framer-n7kv6j-container { flex: none; height: 32px; position: relative; width: 32px; }", ".framer-DHOxX .framer-45y7y5 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DHOxX.framer-szax3m { gap: 0px; } .framer-DHOxX.framer-szax3m > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-DHOxX.framer-szax3m > :first-child { margin-left: 0px; } .framer-DHOxX.framer-szax3m > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 143.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"h5mTi1koL":"warren"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFzrjgKX8H: React.ComponentType<Props> = withCSS(Component, css, "framer-DHOxX") as typeof Component;
export default FramerFzrjgKX8H;

FramerFzrjgKX8H.displayName = "Section-Badge";

FramerFzrjgKX8H.defaultProps = {height: 32, width: 143.5};

addPropertyControls(FramerFzrjgKX8H, {h5mTi1koL: {defaultValue: "Section Title", displayTextArea: false, title: "Warren", type: ControlType.String}} as any)

addFonts(FramerFzrjgKX8H, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuLyfMZ1rib2Bg-4.woff2", weight: "400"}]}, ...LogoFonts], {supportsExplicitInterCodegen: true})